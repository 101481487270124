<template>
  <div class="block_center">
    <img class="img-size mt-3" v-if="imgSource" :src="imgSource"/>
    <p class="text-center mt-5 text-size">
      Der Registrierungsvorgang wurde erfolgreich abgeschlossen! <br>
      Eine Bestätigungs E-Mail senden wir dazu an deine angegebene E-Mail-Adresse!
    </p>
    <div class="mt-3">
      <a type="button" class="btn btn-primary" href="https://cloudgateway.riecken.io/">Weiter zur Homepage</a>
    </div>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import RequestManager from "@/services/RequestManager";

export default {
  name: "CloudgatewayComponent",
  data: () => ({
    text: '',
    loading: true,
    customerContractId: null,
    imgSource: null
  }),
  created() {
    const route = useRoute();
    const routeParams = route.params;

    if (routeParams['customerContractId'] !== undefined && routeParams['customerContractId'] !== null) {
      this.customerContractId = routeParams['customerContractId'];
      this.loading = false;

      RequestManager.get('/customerContract/' + this.customerContractId + '/logo').then(
          async logoResponse => {
            const logoResult = await logoResponse.json();
            if (logoResult['logo'] !== undefined) {
              this.imgSource = logoResult['logo'];
            }
          }
      ).catch((error) => {
        console.log(error);
      })

    }
  }
}
</script>

<style scoped>
.block_center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
