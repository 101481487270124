<script>
import RequestManager from "@/services/RequestManager";

export default {

  props: {},
  downloadFile(event, fileName, customerId = null, customerRegistrationId = null, contractId = null) {
    event.preventDefault();

    if (event.target.id !== undefined) {
      RequestManager.get('', process.env.VUE_APP_BASE_API + '/registration/contract/contractDocuments/' + event.target.id
          + (customerId !== null ? ('?customerId=' + customerId) : '')
          + (customerRegistrationId !== null ? ('&customerRegistrationId=' + customerRegistrationId) : '')
          + (contractId !== null ? ('&contractId=' + contractId) : '')).then(async response => {
        const file = await response.blob();
        this.downloadPdf(file, fileName + '.pdf');
      });
    }
  },
  downloadPdf(blob, fileName) {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }
}
</script>
