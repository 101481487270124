<template>
  <div class="mb-5 pb-5 mt-5 margin-template">
    <div class="mt-5">
      <div class="text-center">
        <img class="img-size mt-3" src="../assets/img/riecken-logo-slogan-rgb-small.png"/>
        <div class="mt-3">
          <h4 v-if="notFound">Die angegebene Seite konnte nicht gefunden werden.
          </h4>
          <h4 class="pt-1">Wechsle auf eines unserer Registrierungsformulare:</h4>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-3">
        <ul class="ul-font">
          <li @click="changeToPage('/basic')"><a href="" @click="$event.preventDefault()">Kundenregistrierung</a></li>
<!--          <li @click="changeToPage('/cloudgateway')"><a href="" @click="$event.preventDefault()">Cloudgateway</a></li>-->
          <li @click="changeToPage('/fileconverter')"><a href="" @click="$event.preventDefault()">Fileconverter</a></li>
<!--          <li @click="changeToPage('/debitorcloud-taxfirm')"><a href="" @click="$event.preventDefault()">Debitorcloud-->
<!--            für Steuerkanzleien</a></li>-->
<!--          <li @click="changeToPage('/debitorcloud-taxfirm-training')"><a href="" @click="$event.preventDefault()">Debitorcloud-->
<!--            für Steuerberater inkl. Schulung</a></li>-->
<!--          <li @click="changeToPage('/debitorcloud-company')"><a href="" @click="$event.preventDefault()">-->
<!--            Debitorcloud für Unternehmen</a></li>-->
<!--          <li @click="changeToPage('/debitorcloud-company-training')"><a href="" @click="$event.preventDefault()">Debitorcloud-->
<!--            für Unternehmen inkl. Schulung</a></li>-->
<!--          <li @click="changeToPage('/debitorcloud-taxfirm-munich-2023')"><a href="" @click="$event.preventDefault()">Debitorcloud-->
<!--            - Stb. Expo München 2023</a></li>-->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";

export default {
  name: "NotFoundComponent",
  props: {},
  watch: {},
  created() {
    const route = useRoute();

    if (route.path === '/forms') {
      this.notFound = false;
    }

  },
  data: () => ({
    notFound: true
  }),
  methods: {
    changeToPage(route) {
      console.log(process.env.VUE_APP_FRONTEND);
      window.open(process.env.VUE_APP_FRONTEND + route, "_self");
    }
  }

}
</script>

<style scoped>

@media (min-width: 769px) {
  .img-size {
    width: 35%;
  }

}

@media (max-width: 769px) {
  .img-size {
    width: 100%;
  }
}

.ul-font {
  font-size: 1.25rem;
}

.margin-template {
  margin-right: 21rem;
  margin-left: 21rem;
}

</style>
