<template>
  <div class="block_center">
    <img class="img-size mt-3" src="../../assets/img/riecken-logo-slogan-rgb-small.png"/>
    <div v-if="loading" class="text-center mt-5 ">
      <div class="spinner-border text-primary text-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="loadingText" v-html="loadingText"></div>
    </div>
    <h2 class="text-center mt-5 text-size" v-html="text">
    </h2>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import RequestManager from "@/services/RequestManager";
import {useToast} from "vue-toastification";

export default {
  name: "FileConverterModule",
  data: () => ({
    text: '',
    loading: true,
    loadingText: null
  }),
  created() {
    const route = useRoute();
    const routeParams = route.params;

    if (routeParams['customerContractId'] !== undefined && routeParams['customerContractId'] !== null) {
      setTimeout(async function () {
        this.loadingText = 'Dein Fileconverter-Account wird gerade für dich erstellt.'
      }, 3000);

      setTimeout(async function () {
        this.loadingText += '<br/> Bitte habe einen Moment Geduld und verlasse diese Seite nicht!';
      }, 5000);
      RequestManager.post('/fileconverter/create/customerContractId/' + routeParams['customerContractId']).then(
          async response => {
            if (response.status === 409) {
              const toast = useToast();
              toast.error("Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! Melden Sie das Problem bei support@riecken.io!");
              this.text = "Dein Fileconverter Account konnte nicht erstellt werden, weil deine E-Mail Adresse bereits mit einem Fileconverter Account verknüpft ist. <br/>" +
                  "Bitte nutze die Passwort Vergessen Funktion unter https://app.fileconverter.riecken.io <br/> oder <br/> wende dich an den Support unter support@riecken.io";
            } else {
              response = await response.json();
              if (response['status'] !== undefined && response['status'] !== null && response['status'] === 'OK') {
                this.text = "Dein Fileconverter-Account wurde erfolgreich erstellt. <br/> Die Registrierung ist nun abgeschlossen!";
              } else {
                const toast = useToast();
                toast.error("Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
                this.text = "Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! <br/>  Melde das Problem bei support@riecken.io!";
              }
            }

            this.loading = false;
            this.loadingText = null;
          }).catch(async error => {
        this.loading = false;
        this.loadingText = null;
        const toast = useToast();
        toast.error("Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
        this.text = "Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! <br/>  Melde das Problem bei support@riecken.io!";

        console.log(error);
      });
    } else {
      this.loading = false;
      this.loadingText = null;
      const toast = useToast();
      toast.error("Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
      this.text = "Beim Erstellen des Fileconverter-Accounts ist ein Fehler aufgetreten! <br/>  Melde das Problem bei support@riecken.io!";
    }

  }
}
</script>

<style scoped>
.block_center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-size {
  font-size: 1.25rem;
}
</style>
