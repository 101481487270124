<template>

  <div v-if="submitLoading"
       id="cover-spin">
  </div>

  <div v-if="loading"
       class="w-100
            py-2
  text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <template v-if="!registrationFormNotFound">
    <div class="row" v-if="!loading">
      <navbarComponent
          v-bind:imageSource="registrationImage"
          v-bind:registrationForm="registrationForm.name">
      </navbarComponent>
      <div
          :class="[registrationForm.name === 'basic' ? 'col-sm-12' :  'col-sm-7']">
        <div class="mb-5">
          <h3 class="mb-3 ml-3">Stammdaten</h3>
          <CompanySearch v-bind:companySearchText="companySearchText"
                         v-bind:registrationForm="registrationForm.name ?? ''"
                         v-bind:customDataInputPossible="registrationForm.customDataInputPossible"
                         v-bind:supportEmail="registrationForm.supportEmail"
                         @company="newCompanySet"
                         @companyNotFoundOrSet="noCompanyFoundAction"
                         @cancelCompany="cancelCompany">

          </CompanySearch>
          <BaseDataForm class="mt-5"
                        v-bind:companyNotFoundOrSet="companyNotFoundOrSet"
                        v-bind:cancelCompany="cancelCompanyModel"
                        v-bind:company="company"
                        v-bind:contractCeoSignatureRequired="contractCeoSignatureRequired"
                        ref="dataBaseForm">

          </BaseDataForm>

          <div class="mt-5 d-flex justify-contend-begin">
            <div>
              <div class="row mt-2"
                   v-bind:key="contractDocument"
                   v-for="contractDocument in contractDocuments">
                <div class="col-12">
                  <div class="form-check">
                    <input type="checkbox"
                           :id="contractDocument.description"
                           v-model.trim="contractDocument.accepted"
                           class="form-check-input"
                           :disabled="contractDocument.requiresCeosSignature === '1'"/>

                    <label
                        :for="contractDocument.description"
                        class="form-check-label"
                    > <a
                        href=""
                        :id="contractDocument.id"
                        target="_blank"
                        @click="downloadFile($event,contractDocument.description + '_Riecken' +( registrationForm.name !== 'basic' ? '_' + registrationForm.externalTitle : ''))">
                      {{ contractDocument.customerAcceptText }}
                    </a> <span v-if="contractDocument.requiresCeosSignature === '1'">**</span></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-contend-begin displayRegisterButtonDesktop">
            <button class="btn btn-primary text-white mt-3 px-5 text-center registerButtonDesktop"
                    :disabled="!formIsValid() "
                    @click="submitData"
            > {{
                registrationForm && registrationForm.name === 'basic' ? 'Registrieren' : ' Kostenpflichtig registrieren'
              }}
            </button>
          </div>
        </div>
        <div v-show=" contractCeoSignatureRequired" class="mb-3 displayRegisterButtonDesktop">
          <p class="fst-italic">
            ** Dieses Dokument muss nach der abgeschlossenen Registrierung durch einen Geschäftsführer
            {{ getCompanyName() }} unterzeichnet werden.
            Dazu wird der Unterschriftenprozess in einem späteren Schritt eingeleitet
          </p>
        </div>
      </div>


      <div class="col-sm-5 margin-mobile-checkout"
           :style="[registrationForm.name === 'basic' ? 'display:none' : '' ]">
        <CheckoutComponent v-bind:registrationForm="registrationForm.name"
                           v-bind:articles="articles"></CheckoutComponent>
      </div>
    </div>


    <div class="d-flex justify-content-center displayRegisterButtonMobile">
      <button class="btn btn-primary text-white mt-5 px-5 text-center"
              :disabled="!formIsValid() || submitLoading"
              @click="submitData">
        {{ registrationForm && registrationForm.name === 'basic' ? 'Registrieren' : ' Kostenpflichtig registrieren' }}

      </button>
    </div>

    <div v-if="contractCeoSignatureRequired"
         class="mt-3 displayRegisterButtonMobile d-flex justify-content-center ">
      <p class="fst-italic">
        ** Dieses Dokument muss nach der abgeschlossenen Registrierung durch einen Geschäftsführer {{
          getCompanyName()
        }} unterzeichnet werden.
        Dazu wird der Unterschriftenprozess in einem späteren Schritt eingeleitet.
      </p>
    </div>
  </template>
  <template v-if="registrationFormNotFound">
    <NotFoundComponent></NotFoundComponent>
  </template>

</template>

<script>
import CompanySearch from "@/components/CompanySearch";
import BaseDataForm from "@/components/BaseDataForm";
import {useRoute} from 'vue-router';
import CheckoutComponent from "@/components/CheckoutComponent";
import {useToast} from "vue-toastification";
import RequestManager from "@/services/RequestManager";
import NavbarComponent from "@/components/NavbarComponent";
import PDF from "@/services/PDF";
import NotFoundComponent from "@/components/NotFoundComponent";

export default {
  name: 'RegistrationForm',
  components: {
    CheckoutComponent,
    BaseDataForm,
    CompanySearch,
    NavbarComponent,
    NotFoundComponent
  },
  props: {},
  data: () => ({
    companySearchText: null,
    companyNotFoundOrSet: false,
    company: null,
    loading: true,
    registrationImage: null,
    registrationForm: null,
    articles: null,
    contractDocuments: [],
    contractCeoSignatureRequired: false,
    submitLoading: false,
    cancelCompanyModel: false,
    registrationFormNotFound: false
  }),
  created() {

    const route = useRoute();
    const routeParams = route.params.pathMatch ?? [];
    const registrationForm = routeParams[0] ?? 'basic';
    RequestManager.get('/registration/form/' + registrationForm).then(async response => {
      const registrationForm = await response.json();
      this.registrationImage = registrationForm.logo;
      this.registrationForm = registrationForm;
      this.articles = registrationForm.article;
      console.log(registrationForm);
      if(registrationForm.active !== '1') {
        this.registrationFormNotFound = true;
      } else {
        if (registrationForm.contract) {
          if (Array.isArray(registrationForm.contract) && registrationForm.contract.length === 1) {
            // this.contractDocuments=
            if (Array.isArray(registrationForm.contract[0].contractDocuments) && registrationForm.contract[0].contractDocuments.length > 0) {
              registrationForm.contract[0].contractDocuments.forEach((contractDocument) => {
                contractDocument.accepted = false;
                this.contractDocuments.push(contractDocument);
                if (contractDocument.requiresCeosSignature === '1') {
                  this.contractCeoSignatureRequired = true;
                }
              })
            }
          }
        }
      }
      this.loading = false;
    }).catch(() => {

      this.registrationFormNotFound = true;

      this.loading = false;
    });

  },
  methods: {
    cancelCompany(cancelCompany) {
      if (cancelCompany) {
        this.cancelCompanyModel = true;
        this.company = null;
        this.companyNotFoundOrSet = false;
      }

    },
    newCompanySet(company) {
      this.company = company;
      this.companyNotFoundOrSet = true;
    },
    noCompanyFoundAction(value) {
      if (value) {
        this.companyNotFoundOrSet = true;
      }
    },
    downloadFile(event, fileName) {
      event.preventDefault();
      PDF.downloadFile(event, fileName);
    },
    getCompanyName() {


      if (this.$refs === undefined) {
        return 'deines Unternehmens';
      }
      if (this.$refs.dataBaseForm !== undefined && this.$refs.dataBaseForm.formData.companyName !== null) {
        return 'von ' + this.$refs.dataBaseForm.formData.companyName;
      }

      return 'deines Unternehmens';
    },

    formIsValid() {

      let formData = null;
      if (this.$refs.dataBaseForm !== undefined) {
        formData = this.$refs.dataBaseForm.formData;
      } else {
        return false;
      }

      if (formData.companyName === null || formData.companyName === '') {
        return false;
      }
      if (formData.street === null || formData.street === '') {
        return false;
      }
      if (formData.number === null || formData.number === '') {
        return false;
      }
      if (formData.city === null || formData.city === '') {
        return false;
      }
      if (formData.zip === null || formData.zip === '') {
        return false;
      }
      if (formData.country === null || formData.country === '') {
        return false;
      }
      if (formData.uid === null || formData.uid === '') {
        return false;
      }
      if (formData.emailCommunication === null || formData.emailCommunication === '') {
        return false;
      }
      if (formData.checkEmailInvoice) {
        if (formData.emailInvoice === null || formData.emailInvoice === '') {
          return false;
        }
      }
      if (formData.paymentMethod === null || formData.paymentMethod === '') {
        return false;
      }

      if (formData.ceos.length === 0 && this.contractCeoSignatureRequired) {
        return false;
      }

      let allConditionsAccepted = true;
      this.contractDocuments.forEach((contractDocument) => {
        if (!contractDocument.accepted && contractDocument.requiresCeosSignature !== '1') {
          allConditionsAccepted = false;
        }
      });

      return allConditionsAccepted;
    },
    submitData() {
      this.submitLoading = true;
      const toast = useToast();
      const formData = this.$refs.dataBaseForm.formData;

      if (!this.$refs.dataBaseForm.checkboxForEmailInvoiceChecked) {
        if (formData.emailInvoice === null || formData.emailInvoice === '') {
          formData.emailInvoice = formData.emailCommunication;
        }
      }

      formData.registrationFormName = this.registrationForm.name;

      RequestManager.post('/customer/create', formData).then(async response => {
        if (await response.status !== 201) {
          toast.error("Bei der Registrierung ist ein Fehler aufgetreten!")

        } else {
          let responseJson = await response.json();
          if (responseJson.redirectUrl) {
            toast.success('Erfolgreich registriert!\nSie werden nun zum externen Zahlungsdienstleiser weitergeleitet, um Ihre Zahlungsdaten zu bestätigen', {
              timeout: 4000
            });
            setTimeout(async function () {
              window.open(responseJson.redirectUrl, "_self");
            }, 3000);
          } else {
            toast.error("Bei der Registrierung ist ein Fehler aufgetreten!")
          }
        }
        this.submitLoading = false;
      })
          .catch(error => {
            this.submitLoading = false;
            toast.error("Bei der Registrierung ist ein Fehler aufgetreten!");
            console.log(error);
          })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media (max-width: 768px) {
  .displayRegisterButtonDesktop {
    display: none !important;
  }

  .margin-mobile-checkout {
    margin-top: 2rem;
  }

}

@media (min-width: 769px) {
  .displayRegisterButtonMobile {
    display: none !important;
  }

  .registerButtonDesktop {
    font-size: 1.5rem;
  }
}

#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 9999;

}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: '';
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: #0d6efd;
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}


</style>
