<template>
  <div class="block_center">
    <img class="img-size mt-3" src="../../assets/img/riecken-logo-slogan-rgb-small.png"/>
    <h2 class="text-center mt-5 text-size">
      Der Registrierungsvorgang wurde erfolgreich abgeschlossen! <br>
      Eine Bestätigungs E-Mail senden wir dazu an deine angegebene E-Mail-Adresse!
    </h2>
  </div>
</template>
<script>
export default {
  name: "BasicModuleComponent",
  data: () => ({
    text: ''
  }),
}
</script>

<style scoped>
.block_center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
