<template>

  <div v-if="loading" class="text-center mt-5 block_center">
    <div class="spinner-border text-primary text-center" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="block_center">
    <img class="img-size mt-3" v-if="imageSource" :src="imageSource"/>

    <div v-if="documentRequiresSignature && !submitCeos" class="mt-5">
      <p><b>Vielen Dank für die Registrierung!</b> <br/><br/>
        Noch sind wir nicht ganz fertig. Unsere Compliance Regeln sehen vor, dass folgende Dokumente durch einen
        Geschäftsführer der Firma
        {{ customer.name }} unterschrieben werden. <br/> <br/></p>
      <ul>
        <li v-bind:key="document" v-for="document in documents">
          <a
              href=""
              :id="document.id"
              target="_blank"
              class="font-size"
              @click="downloadFile($event,document.description + '_Riecken' +( registrationForm.name !== 'basic' ? '_' + registrationForm.externalTitle : ''))">
            {{ document.description }}
          </a>
        </li>
      </ul>
      <br/>
      <p>Unten findest du eine Liste der uns vorliegenden Geschäftsführer und Prokuristen. <br/>
        Bitte wähle einen Geschäftsführer aus, der die Unterschrift leisten soll. <br/>
        Danach gibst du die E-Mail Adresse der Person ein, da diese von unserer digitalen Signaturlösung zur
        elektronischen Signatur direkt aufgefordert wird.
        <br/>
        <br/></p>
      <div v-bind:key="ceo" v-for="ceo in ceos">
        <div class="form-check">
          <input type="checkbox"
                 :id="'checkboxForCEO'+ceo.firstName + ' ' +ceo.lastName"
                 :disabled="loading"
                 v-model.trim="ceo.selected"
                 class="form-check-input checkbox-size"
                 @click="ceoClicked(ceo.id)"/>

          <label
              :for="'checkboxForCEO'+ceo.firstName + ' ' +ceo.lastName"
              class="form-check-label font-size"
          >{{ ceo.firstName + ' ' + ceo.lastName }}</label>
        </div>
        <div v-if="ceo.selected" class="mt-3">
          <div class="form-floating">
            <input type="email"
                   :id="'emailCEO'+ceo.firstName + ' ' +ceo.lastName"
                   :disabled="loading"
                   v-model.trim="ceo.email"
                   placeholder="E-Mail"
                   class="form-control"/>
            <label
                :for="'emailCEO'+ceo.firstName + ' ' +ceo.lastName"
            >E-Mail für {{ ceo.type && ceo.type !== '' ? ceo.type : 'Geschäftsführer/in' }} <span
                class="text-danger">*</span></label>
          </div>

        </div>
      </div>

      <br/>
      <p class="mt-2"><b>Wichtig:</b> Erst nach der geleisteten Unterschrift kannst du mit der Einrichtung der
        Softwarelösung fortfahren</p>
      <div class="mt-3 d-flex align-items-center justify-content-start">
        <div class="">
          <button class="btn btn-primary px-3 py-2 mt-3"
                  :disabled="!formValid() || submitLoading"
                  @click="submitSignatureForm()">
            Bestätigen
          </button>
        </div>
        <div v-if="submitLoading" class="ms-2">
          <div class="spinner-border text-primary text-center" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <p v-if="((!documentRequiresSignature && !loading) || submitCeos) &&redirect" class="text-center mt-5 text-size"
       v-html="text"></p>
    <p v-if="(!redirect && !submitLoading) " class="mt-5 text-size"
       v-html="text"></p>
    <p class="text-center text-size" v-if="((!documentRequiresSignature && !loading) || submitCeos) &&redirect"
       v-html="subtext">
    </p>
    <p class="text-center text-size" v-if="(!redirect && !submitLoading)" v-html="subtext">
    </p>
    <div v-if="((!documentRequiresSignature && !loading) || submitCeos) && redirect" class="mt-5">
      <div class="spinner-border text-primary text-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import RequestManager from "@/services/RequestManager";
import {useRoute} from "vue-router/dist/vue-router";
import PDF from "@/services/PDF";
import {useToast} from "vue-toastification";

export default {
  name: "StripeSuccess",
  created() {
    const route = useRoute();
    let queryParms = route.query;
    if (queryParms.session_id && queryParms.customerId && queryParms.paymentMethod && queryParms.contractId && queryParms.customerRegistrationId) {
      this.customerId = queryParms.customerId;
      this.customerRegistrationId = queryParms.customerRegistrationId;

      RequestManager.post('/paymentMethod/update?sessionId=' + queryParms.session_id
          + '&customerId=' + queryParms.customerId
          + '&paymentMethod=' + queryParms.paymentMethod
          + '&contractId=' + queryParms.contractId
          + '&customerRegistrationId= ' + queryParms.customerRegistrationId, '').then(
          async responseStripe => {

            if (await responseStripe.status !== 200) {
              this.loading = false;
              this.text = 'Es gab einen Fehler beim Speichern der Zahlmethode! <br/> Melde das Problem bei support@riecken.io!';
            } else {
              RequestManager.get('/customer/' + queryParms.customerId + '/registrationForm/contract/' + queryParms.contractId).then(
                  async responseCustomer => {
                    const resultCustomer = await responseCustomer.json();
                    this.customer = resultCustomer;

                    if (this.registrationFormValid(resultCustomer, queryParms.customerId)) {
                      const registrationForms = this.getRegistrationFormsFromResponse(resultCustomer, queryParms.customerId);


                      this.registrationForm = Object.values(registrationForms).pop();
                      this.imageSource = this.registrationForm.logo ?? null;

                      if (this.registrationForm.contract !== undefined
                          && this.registrationForm.contract !== null
                          && Array.isArray(this.registrationForm.contract)
                          && this.registrationForm.contract.length === 1) {

                        this.contract = Object.values(this.registrationForm.contract).pop();
                      }
                    }


                    this.documentRequiresSignature = this.atLeastOneDocumendRequiresASignature(this.registrationForm);

                    if (this.documentRequiresSignature) {
                      this.ceos = this.getCeosFromCustomerResponse(resultCustomer);
                      this.documents = this.getSignatureDocumentsFromRegistrationForm(this.registrationForm);
                      this.loading = false;
                    } else {

                      this.submitSignatureForm()
                    }

                  }).catch((error) => {
                this.loading = false;
                console.log(error);
                this.text = 'Es gab einen Fehler beim Registrierungsprozess! <br/> Melde das Problem bei support@riecken.io!';
              });

            }

          }).catch(error => {
        this.text = 'Es gab einen Fehler beim Speichern der Zahlmethode! <br/> Melde das Problem bei support@riecken.io!';
        this.loading = false;
        console.log(error);
      });
    } else {
      this.text = 'Es gab einen Fehler beim Speichern der Zahlmethode! <br/>  Melde das Problem bei support@riecken.io!';
      this.loading = false;
    }

  },
  data: () => ({
    loading: true,
    text: '',
    registrationForm: null,
    ceos: [],
    documents: [],
    documentRequiresSignature: false,
    customerId: null,
    submitCeos: false,
    submitLoading: false,
    subtext: '',
    contract: null,
    redirect: true,
    customerRegistrationId: null,
    customer: null,
    imageSource: null
  }),
  methods: {
    registrationFormValid(customerResponse, customerId) {
      const registrationForms = this.getRegistrationFormsFromResponse(customerResponse, customerId);
      return registrationForms.length === 1;
    },
    getRegistrationFormsFromResponse(customerResponse, customerId) {

      if (customerResponse.customerRegistration !== undefined && Array.isArray(customerResponse.customerRegistration)) {
        for (let customerRegistration of customerResponse.customerRegistration) {
          if (customerRegistration.customerId == customerId) {
            if (customerRegistration.registrationForms !== undefined && Array.isArray(customerRegistration.registrationForms)) {
              return customerRegistration.registrationForms;
            }
          }
        }

      }
      return [];
    },
    atLeastOneDocumendRequiresASignature(registrationForm) {

      if (registrationForm.contract !== undefined) {
        if (Object.values(registrationForm.contract).length === 1) {
          const contract = Object.values(registrationForm.contract).pop();
          if (contract.contractDocuments !== undefined) {
            if (Array.isArray(contract.contractDocuments)) {
              let signatureRequired = false;

              for (let document of contract.contractDocuments) {

                if (document.requiresCeosSignature === '1') {
                  signatureRequired = true;
                  break;
                }
              }
              return signatureRequired;
            }
          }
        }
      }

      return false;
    },
    getCeosFromCustomerResponse(resultCustomer) {
      if (resultCustomer.customerCEOs !== undefined) {
        if (Array.isArray(resultCustomer.customerCEOs)) {
          const ceos = [];
          resultCustomer.customerCEOs.forEach((ceo) => {
            ceo.selected = false;
            ceo.email = '';
            ceos.push(ceo);
          });
          return ceos;
        }
      }
      return [];
    },
    getSignatureDocumentsFromRegistrationForm(registrationForm) {
      if (registrationForm.contract !== undefined) {
        if (Object.values(registrationForm.contract).length === 1) {
          const contract = Object.values(registrationForm.contract).pop();
          if (contract.contractDocuments !== undefined) {
            if (Array.isArray(contract.contractDocuments)) {
              const signatureDocuments = [];
              for (let document of contract.contractDocuments) {
                if (document.requiresCeosSignature === '1') {
                  signatureDocuments.push(document);
                }
              }
              return signatureDocuments;
            }
          }
        }
      }
      return [];
    },
    downloadFile(event, fileName) {
      event.preventDefault();
      PDF.downloadFile(event, fileName, this.customerId, this.customerRegistrationId, this.contract.id);
    },
    ceoClicked(ceoId) {
      console.log(ceoId);
    },
    formValid() {

      let formValid = true;
      if (this.documentRequiresSignature) {
        let selectedCeos = 0;
        this.ceos.forEach((ceo) => {

          if (ceo.selected) {
            selectedCeos++;
          }
          if (ceo.selected && ceo.email === '') {
            formValid = false;
          }
        });
        if (selectedCeos === 0) {
          formValid = false;
        }
      }

      return formValid;
    },
    submitSignatureForm() {

      this.loading = false;
      this.submitLoading = true;
      RequestManager.post('/contract/' + this.contract.id + '/customer/' + this.customerId + '/customerRegistration/' + this.customerRegistrationId + '/ceos/signers', {'ceos': Object.values(this.ceos)}).then(
          async response => {
            response = await response.json();

            if (response['customerContractId'] !== undefined && response['customerContractId'] !== null) {
              this.submitCeos = true;
              RequestManager.get('/registration/contract/' + this.contract.id + '/nextSteps/' + response['customerContractId']).then(
                  async responseNextSteps => {
                    responseNextSteps = await responseNextSteps.json();


                    this.submitLoading = false;

                    if (responseNextSteps['redirect'] !== undefined && responseNextSteps['redirect'] !== null && responseNextSteps['redirect'] === false) {
                      this.redirect = false;
                      if (responseNextSteps['frontendTextAfterRegistration'] !== undefined) {

                        this.text = responseNextSteps['frontendTextAfterRegistration'];

                      } else {
                        this.text = "Danke für die Registrierung bei Riecken.io.";
                        this.subtext = 'Alle weiteren Infos bekommst du per E-Mail zugesendet. <br/>';
                        if (this.documentRequiresSignature) {
                          this.subtext += 'Achte darauf, dass die nötigen Dokumente unterzeichnet werden';
                        }
                      }

                      throw new Error("REDIRECT_FALSE");
                    }

                    if (responseNextSteps['type'] !== undefined && responseNextSteps['type'] !== null && responseNextSteps['REDIRECT_URL'] !== null
                        && responseNextSteps['data'] !== undefined && responseNextSteps['data'] !== null) {
                      this.text = 'Deine Unternehmer- und Zahlungsdaten wurden erfolgreich abgespeichert.';
                      this.subtext = 'Du wirst nun auf eine weitere Seite weitergeleitet, um deine Registrierung fortzuführen/abzuschließen';
                      setTimeout(async function () {
                        window.open(responseNextSteps['data'], "_self");
                      }, 3000);
                    } else {
                      const toast = useToast();
                      toast.error("Beim Laden der nächsten Schritte ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
                      this.submitLoading = false;
                    }

                  }).catch((error => {
                    const toast = useToast();
                    console.log(error.message);
                    if (error.message != 'REDIRECT_FALSE') {
                      toast.error("Beim Laden der nächsten Schritte ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
                    }
                    this.submitLoading = false;
                  })
              )

            }

            this.submitLoading = false;
          }).catch((error) => {
        const toast = useToast();
        toast.error("Bei der Speicherung von den Zeichnungsberechtigten ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
        console.log(error);
        this.submitLoading = false;
      })

    }
  }


}
</script>

<style scoped>
.block_center {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 769px) {
  .img-size {
    width: 20vw;
    margin-bottom: 2rem;
  }
}

@media (max-width: 769px) {
  .img-size {
    padding-top: 15rem;
    width: 75%;
  }

  .text-size {
    font-size: 1.25rem;
  }
}

p {
  line-height: 1.5 !important;
}

.font-size {
  font-size: 1.15rem !important;
}

.checkbox-size {
  width: 1.15rem;
  height: 1.15rem;
  margin-right: 0.75rem;
}
</style>
