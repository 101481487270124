<template>


  <div>

    <div v-if="loading"
         class="w-100
            py-2
            mb-5
  text-center">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>


    <template v-if="(!loading && company) || companySet">
      <div class="row ">
        <div class="col-12">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="Name der Firma"
                id="companyName"
                :disabled="!companySet || loading"
                v-model.trim="formData.companyName"
                class="form-control"
            />
            <label
                for="companyName">
              Name der Firma <span class="text-danger">*</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-5">

        <div class="col-sm-8">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="Straße"
                id="street"
                :disabled="!companySet || loading"
                v-model.trim="formData.street"
                class="form-control"/>
            <label for="street">
              Straße <span class="text-danger">*</span>
            </label>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="Nummer"
                id="number"
                :disabled="!companySet || loading"
                v-model.trim="formData.number"
                class="form-control"/>
            <label for="number">
              Nummer <span class="text-danger">*</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row mt-2">

        <div class="col-sm-4">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="PLZ"
                id="zip"
                :disabled="!companySet || loading"
                v-model.trim="formData.zip"
                class="form-control"/>

            <label for="zip">
              PLZ <span class="text-danger">*</span>
            </label>
          </div>
        </div>

        <div class="col-sm-8">
          <div class="form-floating">
            <input

                autocomplete="off"
                placeholder="Ort"
                id="city"
                :disabled="!companySet || loading"
                v-model.trim="formData.city"
                class="form-control"/>
            <label for="city">
              Stadt/Ort <span class="text-danger">*</span>
            </label>
          </div>
        </div>

      </div>

      <div class="row mt-2">
        <div class="col-12">
          <div class="form-floating">
            <select
                id="country"
                :disabled="!companySet || loading"
                v-model.trim="formData.country"
                class="form-select">
              <CountrySelectOptionsComponent></CountrySelectOptionsComponent>
            </select>
            <label for="country">Wähle ein Land <span class="text-danger">*</span></label>
          </div>
        </div>
      </div>

      <div class="row mt-5 pb-3">
        <div class="col-12">
          <div class="form-floating">
            <input
                autocomplete="off"
                :class="[!this.vatValid ? 'is-invalid' : ((formData.uid == null || formData.uid === '') ? '' : 'is-valid')]"
                placeholder="UID Nummer"
                id="uid"
                :disabled="!companySet || loading"
                v-model.trim="formData.uid"
                @keyup="uidChanged"
                @change="uidChanged"
                class="form-control"/>
            <label
                for="uid"
            >UID Nummer <span class="text-danger">*</span></label>
          </div>
        </div>

      </div>

      <hr>

      <div class="row pt-3">
        <div class="col-12">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="a@example.com"
                id="emailForCommunication"
                type="email"
                :disabled="!companySet "
                v-model.trim="formData.emailCommunication"
                class="form-control"/>
            <label
                for="emailForCommunication"
            >E-Mail für Kommunikation <span class="text-danger">*</span></label>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12">
          <div class="form-check">
            <input
                autocomplete="off"
                type="checkbox"
                id="checkboxForEmailInvoice"
                :disabled="!companySet || loading"
                v-model.trim="formData.checkEmailInvoice"
                class="form-check-input"
                @click="checkboxForEmailInvoiceClicked"/>

            <label
                for="checkboxForEmailInvoice"
                class="form-check-label"
            >Andere E-Mail Adresse für Rechnungen verwenden</label>
          </div>
        </div>
      </div>


      <div class="row mt-2" v-if="checkboxForEmailInvoiceChecked">
        <div class="col-12">
          <div class="form-floating">
            <input
                autocomplete="off"
                placeholder="a@example.com"
                id="emailForInvoice"
                type="email"
                :disabled="!companySet || loading"
                v-model.trim="formData.emailInvoice"
                class="form-control"/>
            <label
                for="emailForInvoice"
            >E-Mail für Rechnungen <span class="text-danger">*</span></label>
          </div>
        </div>
      </div>

      <div class="margin-phone-right mt-4 pb-3">

        <div class="col-12">
          <MazPhoneNumberInput
              v-model="formData.phone"
              :disabled="!companySet || loading"
              id="phoneNumber"
              :preferred-countries="['AT','DE']"
              :translations="{
                countrySelector: {
                  placeholder: 'Ländervorwahl',
                  error: 'Wählen Sie ein Land',
                },
                phoneInput: {
                    placeholder:'Telefon'
                }

              }"
              color="primary"
              :no-example="true"
          >
          </MazPhoneNumberInput>
        </div>
      </div>


      <hr>

      <div class="row pt-3">
        <div class="col-12">
          <div>
            <label
                class="col-12 text-left
            left
  align-center"
            >Wähle eine Zahlungsart <span class="text-danger">*</span></label>

          </div>

          <div class="mt-3 ml-3">
            <div class="form-check" id="paymentMethodCredit">
              <input
                  class="form-check-input"
                  id="credit"
                  type="radio"
                  value="CREDITCARD"
                  v-model="formData.paymentMethod"
                  :disabled="!companySet || loading"
              />
              <label class="form-check-input-label" for="credit">Kreditkarte</label>
            </div>

            <div class="form-check" id="paymentMethodSepa">
              <input class="form-check-input"
                     id="sepa"
                     type="radio"
                     v-model="formData.paymentMethod"
                     :disabled="!companySet || loading"
                     value="SEPA"
              />
              <!--              :disabled="!companySet || loading"-->
              <label class="form-check-label" for="SEPA">SEPA-Lastschrift</label>
            </div>
          </div>
        </div>
      </div>
    </template>

  </div>


</template>

<script>
import RequestManager from "@/services/RequestManager";
import CountrySelectOptionsComponent from "@/components/CountrySelectOptionsComponent";
import {useToast} from "vue-toastification";

export default {
  name: "BaseDataForm",
  components: {CountrySelectOptionsComponent},
  props: {
    company: Object,
    companyNotFoundOrSet: Boolean,
    cancelCompany: Boolean,
    contractCeoSignatureRequired: Boolean
  },
  data: () => ({
    checkboxForEmailInvoiceChecked: false,
    formData: {
      companyName: null,
      publicRegisterApiId: null,
      mainJurisdiction: null,
      street: null,
      number: null,
      city: null,
      zip: null,
      country: null,
      uid: null,
      phone: null,
      emailCommunication: null,
      checkEmailInvoice: null,
      emailInvoice: null,
      paymentMethod: 'CREDITCARD',
      paymentMethodContent: {
        creditCardNumber: null,
        creditCardExpiration: null,
        creditCardCvc: null
      },
      ceos: [],
      website: null
    },
    countryListAlpha2: {
      "AF": "Afghanistan",
      "AL": "Albania",
      "DZ": "Algeria",
      "AS": "American Samoa",
      "AD": "Andorra",
      "AO": "Angola",
      "AI": "Anguilla",
      "AQ": "Antarctica",
      "AG": "Antigua and Barbuda",
      "AR": "Argentina",
      "AM": "Armenia",
      "AW": "Aruba",
      "AU": "Australia",
      "AT": "Austria",
      "AZ": "Azerbaijan",
      "BS": "Bahamas (the)",
      "BH": "Bahrain",
      "BD": "Bangladesh",
      "BB": "Barbados",
      "BY": "Belarus",
      "BE": "Belgium",
      "BZ": "Belize",
      "BJ": "Benin",
      "BM": "Bermuda",
      "BT": "Bhutan",
      "BO": "Bolivia (Plurinational State of)",
      "BQ": "Bonaire, Sint Eustatius and Saba",
      "BA": "Bosnia and Herzegovina",
      "BW": "Botswana",
      "BV": "Bouvet Island",
      "BR": "Brazil",
      "IO": "British Indian Ocean Territory (the)",
      "BN": "Brunei Darussalam",
      "BG": "Bulgaria",
      "BF": "Burkina Faso",
      "BI": "Burundi",
      "CV": "Cabo Verde",
      "KH": "Cambodia",
      "CM": "Cameroon",
      "CA": "Canada",
      "KY": "Cayman Islands (the)",
      "CF": "Central African Republic (the)",
      "TD": "Chad",
      "CL": "Chile",
      "CN": "China",
      "CX": "Christmas Island",
      "CC": "Cocos (Keeling) Islands (the)",
      "CO": "Colombia",
      "KM": "Comoros (the)",
      "CD": "Congo (the Democratic Republic of the)",
      "CG": "Congo (the)",
      "CK": "Cook Islands (the)",
      "CR": "Costa Rica",
      "HR": "Croatia",
      "CU": "Cuba",
      "CW": "Curaçao",
      "CY": "Cyprus",
      "CZ": "Czechia",
      "CI": "Côte d'Ivoire",
      "DK": "Denmark",
      "DJ": "Djibouti",
      "DM": "Dominica",
      "DO": "Dominican Republic (the)",
      "EC": "Ecuador",
      "EG": "Egypt",
      "SV": "El Salvador",
      "GQ": "Equatorial Guinea",
      "ER": "Eritrea",
      "EE": "Estonia",
      "SZ": "Eswatini",
      "ET": "Ethiopia",
      "FK": "Falkland Islands (the) [Malvinas]",
      "FO": "Faroe Islands (the)",
      "FJ": "Fiji",
      "FI": "Finland",
      "FR": "France",
      "GF": "French Guiana",
      "PF": "French Polynesia",
      "TF": "French Southern Territories (the)",
      "GA": "Gabon",
      "GM": "Gambia (the)",
      "GE": "Georgia",
      "DE": "Germany",
      "GH": "Ghana",
      "GI": "Gibraltar",
      "GR": "Greece",
      "GL": "Greenland",
      "GD": "Grenada",
      "GP": "Guadeloupe",
      "GU": "Guam",
      "GT": "Guatemala",
      "GG": "Guernsey",
      "GN": "Guinea",
      "GW": "Guinea-Bissau",
      "GY": "Guyana",
      "HT": "Haiti",
      "HM": "Heard Island and McDonald Islands",
      "VA": "Holy See (the)",
      "HN": "Honduras",
      "HK": "Hong Kong",
      "HU": "Hungary",
      "IS": "Iceland",
      "IN": "India",
      "ID": "Indonesia",
      "IR": "Iran (Islamic Republic of)",
      "IQ": "Iraq",
      "IE": "Ireland",
      "IM": "Isle of Man",
      "IL": "Israel",
      "IT": "Italy",
      "JM": "Jamaica",
      "JP": "Japan",
      "JE": "Jersey",
      "JO": "Jordan",
      "KZ": "Kazakhstan",
      "KE": "Kenya",
      "KI": "Kiribati",
      "KP": "Korea (the Democratic People's Republic of)",
      "KR": "Korea (the Republic of)",
      "KW": "Kuwait",
      "KG": "Kyrgyzstan",
      "LA": "Lao People's Democratic Republic (the)",
      "LV": "Latvia",
      "LB": "Lebanon",
      "LS": "Lesotho",
      "LR": "Liberia",
      "LY": "Libya",
      "LI": "Liechtenstein",
      "LT": "Lithuania",
      "LU": "Luxembourg",
      "MO": "Macao",
      "MG": "Madagascar",
      "MW": "Malawi",
      "MY": "Malaysia",
      "MV": "Maldives",
      "ML": "Mali",
      "MT": "Malta",
      "MH": "Marshall Islands (the)",
      "MQ": "Martinique",
      "MR": "Mauritania",
      "MU": "Mauritius",
      "YT": "Mayotte",
      "MX": "Mexico",
      "FM": "Micronesia (Federated States of)",
      "MD": "Moldova (the Republic of)",
      "MC": "Monaco",
      "MN": "Mongolia",
      "ME": "Montenegro",
      "MS": "Montserrat",
      "MA": "Morocco",
      "MZ": "Mozambique",
      "MM": "Myanmar",
      "NA": "Namibia",
      "NR": "Nauru",
      "NP": "Nepal",
      "NL": "Netherlands (the)",
      "NC": "New Caledonia",
      "NZ": "New Zealand",
      "NI": "Nicaragua",
      "NE": "Niger (the)",
      "NG": "Nigeria",
      "NU": "Niue",
      "NF": "Norfolk Island",
      "MP": "Northern Mariana Islands (the)",
      "NO": "Norway",
      "OM": "Oman",
      "PK": "Pakistan",
      "PW": "Palau",
      "PS": "Palestine, State of",
      "PA": "Panama",
      "PG": "Papua New Guinea",
      "PY": "Paraguay",
      "PE": "Peru",
      "PH": "Philippines (the)",
      "PN": "Pitcairn",
      "PL": "Poland",
      "PT": "Portugal",
      "PR": "Puerto Rico",
      "QA": "Qatar",
      "MK": "Republic of North Macedonia",
      "RO": "Romania",
      "RU": "Russian Federation (the)",
      "RW": "Rwanda",
      "RE": "Réunion",
      "BL": "Saint Barthélemy",
      "SH": "Saint Helena, Ascension and Tristan da Cunha",
      "KN": "Saint Kitts and Nevis",
      "LC": "Saint Lucia",
      "MF": "Saint Martin (French part)",
      "PM": "Saint Pierre and Miquelon",
      "VC": "Saint Vincent and the Grenadines",
      "WS": "Samoa",
      "SM": "San Marino",
      "ST": "Sao Tome and Principe",
      "SA": "Saudi Arabia",
      "SN": "Senegal",
      "RS": "Serbia",
      "SC": "Seychelles",
      "SL": "Sierra Leone",
      "SG": "Singapore",
      "SX": "Sint Maarten (Dutch part)",
      "SK": "Slovakia",
      "SI": "Slovenia",
      "SB": "Solomon Islands",
      "SO": "Somalia",
      "ZA": "South Africa",
      "GS": "South Georgia and the South Sandwich Islands",
      "SS": "South Sudan",
      "ES": "Spain",
      "LK": "Sri Lanka",
      "SD": "Sudan (the)",
      "SR": "Suriname",
      "SJ": "Svalbard and Jan Mayen",
      "SE": "Sweden",
      "CH": "Switzerland",
      "SY": "Syrian Arab Republic",
      "TW": "Taiwan",
      "TJ": "Tajikistan",
      "TZ": "Tanzania, United Republic of",
      "TH": "Thailand",
      "TL": "Timor-Leste",
      "TG": "Togo",
      "TK": "Tokelau",
      "TO": "Tonga",
      "TT": "Trinidad and Tobago",
      "TN": "Tunisia",
      "TR": "Turkey",
      "TM": "Turkmenistan",
      "TC": "Turks and Caicos Islands (the)",
      "TV": "Tuvalu",
      "UG": "Uganda",
      "UA": "Ukraine",
      "AE": "United Arab Emirates (the)",
      "GB": "United Kingdom of Great Britain and Northern Ireland (the)",
      "UM": "United States Minor Outlying Islands (the)",
      "US": "United States of America (the)",
      "UY": "Uruguay",
      "UZ": "Uzbekistan",
      "VU": "Vanuatu",
      "VE": "Venezuela (Bolivarian Republic of)",
      "VN": "Viet Nam",
      "VG": "Virgin Islands (British)",
      "VI": "Virgin Islands (U.S.)",
      "WF": "Wallis and Futuna",
      "EH": "Western Sahara",
      "YE": "Yemen",
      "ZM": "Zambia",
      "ZW": "Zimbabwe",
      "AX": "Åland Islands"
    },
    companySet: false,
    mobile: false,
    loading: false,
    vatValid: true,
    uidValidationLoading: false
  }),
  created() {
  },
  watch: {
    cancelCompany: function (newValue) {
      if (newValue) {
        this.resetData();
      }
    },
    companyNotFoundOrSet: function (newValue) {
      if (newValue) {
        this.companySet = true;
      }
    },
    company: function (newCompany) {
      if (newCompany != null) {
        if (newCompany.id) {
          this.loading = true;
          this.formData.ceos = [];
          RequestManager.get(newCompany.id + '/dataset/full', 'https://theo-api.riecken.io/index.php/company/')
              .then(async companyDetailsResponse => {
                const companyDetails = await companyDetailsResponse.json();
                if (companyDetails.germanCompanyRegisterData !== undefined && companyDetails.germanCompanyRegisterData !== null) {
                  this.formData.companyName = companyDetails.germanCompanyRegisterData.companyName ?? '';
                  this.formData.publicRegisterApiId = newCompany.id;
                  if (companyDetails.dunBradstreetData !== undefined && companyDetails.dunBradstreetData !== null) {
                    this.formData.country = this.countryListAlpha2[companyDetails.dunBradstreetData.country ?? ''] ?? 'DE';
                  }

                  this.formData.street = companyDetails.germanCompanyRegisterData.street ?? '';
                  this.formData.number = companyDetails.germanCompanyRegisterData.number ?? '';
                  this.formData.city = companyDetails.germanCompanyRegisterData.city ?? '';
                  this.formData.zip = companyDetails.germanCompanyRegisterData.zip ?? '';
                  this.formData.phone = companyDetails.germanCompanyRegisterData.phone ?? '';

                  this.formData.mainJurisdiction = 'DE';
                  if (companyDetails.germanCompanyRegisterData.officers !== undefined
                      && Array.isArray(companyDetails.germanCompanyRegisterData.officers)) {
                    companyDetails.germanCompanyRegisterData.officers.forEach((officer) => {
                      this.formData.ceos.push(officer);
                    })
                  }


                } else if (companyDetails.firmenbuchDetails !== undefined && companyDetails.firmenbuchDetails !== null) {
                  this.formData.companyName = companyDetails.firmenbuchDetails.companyName ?? '';
                  this.formData.publicRegisterApiId = newCompany.id;
                  if (companyDetails.dunBradstreetData !== undefined && companyDetails.dunBradstreetData !== null) {
                    this.formData.country = this.countryListAlpha2[companyDetails.dunBradstreetData.country ?? ''] ?? 'AT';
                  }
                  this.formData.street = companyDetails.firmenbuchDetails.street ?? '';
                  this.formData.number = companyDetails.firmenbuchDetails.number ?? '';
                  this.formData.city = companyDetails.firmenbuchDetails.city ?? '';
                  this.formData.zip = companyDetails.firmenbuchDetails.zip ?? '';
                  this.formData.phone = companyDetails.firmenbuchDetails.phone ?? '';

                  this.formData.mainJurisdiction = 'AT';
                  if (companyDetails.firmenbuchDetails.officers !== undefined
                      && Array.isArray(companyDetails.firmenbuchDetails.officers)) {
                    companyDetails.firmenbuchDetails.officers.forEach((officer) => {
                      this.formData.ceos.push(officer);
                    })
                  }
                } else if (companyDetails.taxConsultantRegisterData !== undefined && companyDetails.taxConsultantRegisterData !== null) {
                  this.formData.companyName = companyDetails.taxConsultantRegisterData.name ?? '';
                  this.formData.publicRegisterApiId = newCompany.id;

                  this.formData.emailCommunication = companyDetails.taxConsultantRegisterData.email ?? '';

                  if (companyDetails.taxConsultantRegisterData.ceos !== undefined
                      && Array.isArray(companyDetails.taxConsultantRegisterData.ceos)) {
                    companyDetails.taxConsultantRegisterData.ceos.forEach((ceo) => {
                      const name = ceo.toString().split(" ");
                      this.formData.ceos.push({
                        name: name[0] ?? '',
                        surname: name[1] ?? '',
                        position: 'Vertretungsberechtigte(r)'
                      });
                    })
                  } else {
                    const toast = useToast();
                    toast.error("Es konnten keine Geschäftsführer gefunden werden.");
                  }
                }

                if (companyDetails.websiteData !== undefined && companyDetails.websiteData !== null) {
                  this.formData.website = companyDetails.websiteData.website ?? '';
                  if (companyDetails.websiteData.vatId !== undefined && companyDetails.websiteData.vatId !== null) {
                    this.formData.uid = companyDetails.websiteData.vatId.vatId ?? '';
                  }
                  if (companyDetails.websiteData.emailAddresses !== undefined
                      && companyDetails.websiteData.emailAddresses !== null
                      && Array.isArray(companyDetails.websiteData.emailAddresses)
                      && companyDetails.websiteData.emailAddresses.length > 0) {
                    this.formData.emailCommunication = companyDetails.websiteData.emailAddresses[0];
                  }
                }


                if (this.contractCeoSignatureRequired && this.formData.ceos.length === 0) {
                  const toast = useToast();
                  toast.error("Es konnten keine Geschäftsführer-Daten aus der gewählten Stammdaten-Quelle gefunden werden.");
                }

                this.companySet = true;
                this.loading = false;
              })
              .catch(error => {
                const toast = useToast();
                toast.error("Es gab einen Fehler beim Abruf der Daten deines Unternehmens. Melde das Problem bei support@riecken.io!");
                this.loading = false;
                console.log(error);
              })
        }

      }

    }
  },
  methods: {
    resetData() {

      this.checkboxForEmailInvoiceChecked = false;
      this.formData.companyName = null;
      this.formData.publicRegisterApiId = null;
      this.formData.mainJurisdiction = null;
      this.formData.street = null;
      this.formData.number = null;
      this.formData.city = null;
      this.formData.zip = null;
      this.formData.country = null;
      this.formData.uid = null;
      this.formData.phone = null;
      this.formData.emailCommunication = null;
      this.formData.checkEmailInvoice = null;
      this.formData.emailInvoice = null;
      this.formData.paymentMethod = 'CREDITCARD';
      this.formData.paymentMethodContent.creditCardNumber = null;
      this.formData.paymentMethodContent.creditCardExpiration = null;
      this.formData.paymentMethodContent.creditCardCvc = null;
      this.formData.ceos = [];
      this.formData.website = null;

      this.companySet = false;
      this.mobile = false;
      this.loading = false;
      this.vatValid = true;
      this.uidValidationLoading = false;
    },
    uidChanged() {

      if (this.formData.uid !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(() => {
          this.uidValidationLoading = true;
          RequestManager.get('/registration/vat/validate/' + this.formData.uid)
              .then(async result => {
                const validResponse = await result.json();
                if (validResponse.successful !== undefined && validResponse.successful === true) {
                  if (validResponse.result !== undefined) {
                    if (validResponse.result.valid !== undefined && validResponse.result.valid === true) {
                      this.vatValid = true;
                    } else {
                      this.vatValid = false;
                    }
                  } else {
                    this.vatValid = false;
                  }
                } else {
                  this.vatValid = false;
                }
                this.uidValidationLoading = false;
              })
              .catch(error => {
                const toast = useToast();
                toast.error("Es gab einen Fehler beim Abruf der Daten deines Unternehmens. Melde das Problem bei support@riecken.io!");
                console.log(error)
                this.vatValid = false;
                this.uidValidationLoading = false;

              })
        }, 400);
      } else {
        this.vatValid = false;
      }

    },
    checkboxForEmailInvoiceClicked(event) {
      this.checkboxForEmailInvoiceChecked = event.target.checked;
      /*
       * if checkbox for other invoice is checked
       */
      if (this.checkboxForEmailInvoiceChecked) {
        /*
         * and email for invoices is empty,
         *  copy email for communications into email for invoice
         */
        if (this.formData.emailInvoice === null || this.formData.emailInvoice === '') {
          this.formData.emailInvoice = this.formData.emailCommunication;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
