<script>
export default {

  props: {
    baseUrl: {
      type: String,
      default: process.env.VUE_APP_BASE_API
    },
    requestHeaders: {'Content-Type': 'application/json'},
    whereConditions: []
  },
  getAuth(
      path,
      searchTerm = null,
      arrayValues = true
  ) {
    /** Adding the Header for Authorization */
    // this.addAuthorizationHeader();

    /** Create the Request URL and add Parameters if neccessary */
    path = path + "?";

    if (arrayValues) {
      path = this.addArrayValuesString(path);
    }

    if (searchTerm) {
      path = this.addSearchTermString(path, searchTerm);
    }

    /**
     * First safe the Request Headers in a variable, because I could not directly reference this.props,
     * Then return the promise from the fetch command to the user
     * */
    const headers = this.props.requestHeaders;

    /**
     * Add Where conditions from array (which is filled by the addWhere Condition Method, beforehand)
     * after that empty the where condition array, so those where conditions do not apply next time
     */
    if (this.props.whereConditions.length > 0) {
      path = this.addWhereConditionToPath(path);
      this.props.whereConditions = [];
    }

    return fetch(this.props.baseUrl.default + path, {headers});

  },

  get(
      path,
      url = null,
      searchTerm = null
  ) {


    if (searchTerm) {
      path = this.addSearchTermString(path, searchTerm);
    }

    /**
     * First safe the Request Headers in a variable, because I could not directly reference this.props,
     * Then return the promise from the fetch command to the user
     * */
    const headers = this.props.requestHeaders;

    /**
     * Add Where conditions from array (which is filled by the addWhere Condition Method, beforehand)
     * after that empty the where condition array, so those where conditions do not apply next time
     */
    if (this.props.whereConditions.length > 0) {
      path = this.addWhereConditionToPath(path);
      this.props.whereConditions = [];
    }

    return fetch((url ?? this.props.baseUrl.default) + path, {headers});

  },

  put(
      path,
      payload
  ) {
    /** Adding the Header for Authorization */
    //this.addAuthorizationHeader();

    /** We put a JSON Object, so we add the Content Type Header */
    this.addContentTypeApplicationJson();

    const requestOptions = {
      method: "PUT",
      headers: this.props.requestHeaders,
      body: JSON.stringify(payload)
    };

    /**
     * Return the actual request
     */
    return fetch(this.props.baseUrl + path, requestOptions);
  },

  post(
      path,
      payload
  ) {

    const requestOptions = {
      method: "POST",
      headers: this.props.requestHeaders,
      body: JSON.stringify(payload)
    };

    /**
     * Return the actual request
     */

    return fetch(this.props.baseUrl.default + path, requestOptions);
  },

  delete(
      path
  ) {
    /** Adding the Header for Authorization */
    //this.addAuthorizationHeader();

    /** We put a JSON Object, so we add the Content Type Header */
    this.addContentTypeApplicationJson();

    const requestOptions = {
      method: "DELETE",
      headers: this.props.requestHeaders,
    };

    /**
     * Return the actual request
     */
    return fetch(this.props.baseUrl.default + path, requestOptions);
  },

  // addAuthorizationHeader() {
  //   this.props.requestHeaders.Authorization = "Bearer " + CookieManager.getCookie("FEURICH_STIMMSYSTEM_ACCESS_TOKEN");
  // },

  addContentTypeApplicationJson() {
    this.props.requestHeaders["Content-Type"] = "application/json";
  },

  addArrayValuesString(path) {
    return path += "array_values=true&";
  },

  addSearchTermString(path, searchTerm) {
    return path += "search_term=" + searchTerm + "&";
  },

  addWhereConditionToPath(path) {
    return path += "where_condition=" + JSON.stringify(this.props.whereConditions) + "&";
  },

  cleanPayload(payload) {
    const payloadNew = {...payload}
    delete payloadNew.id;
    delete payloadNew.entryCreated;
    delete payloadNew.entryUpdated;
    return payloadNew;
  },

  addWhereCondition(field, operator, value, model, followingOperator = null, previousOperator = null) {

    let whereCondition = {
      field: field,
      operator: operator,
      value: value,
      model: model,
    };

    if (followingOperator) {
      whereCondition.followingOperator = followingOperator;
    }

    if (previousOperator) {
      whereCondition.previousOperator = previousOperator;
    }

    this.props.whereConditions.push(whereCondition);

  }

}
</script>
