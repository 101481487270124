<template>
  <div class="text-center mt-5">
    <img class="img-size mt-3" v-if="imgSource" :src="imgSource"/>
    <div v-if="loading" class="text-center mt-5 ">
      <div class="spinner-border text-primary text-center" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-if="loadingText" v-html="loadingText"></div>
    </div>

    <div class="block_center mt-3">
      <template v-if="documentsSigned === false">
        <h4 class="text-center mt-5 mb-2">Die Erstellung der Debitorcloud Instanz ist noch nicht möglich, <br/> weil
          noch
          nicht alle Personen die nötigen Dokumente unterzeichnet haben</h4>
      </template>
      <template v-if="instanceCreated === true">
        <h4 class="mt-5 mb-2">Es wurde schon eine Debitorcloud Instanz mit diesem Link erstellt</h4>
      </template>
      <template v-if="successText === '' && documentsSigned === true && instanceCreated === false">
        <p class="text-start mt-3">Nachdem nun alle Verträge unterschrieben wurden, geht es weiter mit der Eingabe der
          notwendigen Daten für die Debitorcloud. <br/>
          Neben deiner DATEV Beraternummer brauchen wir noch Informationen zu deinem IT Ansprechpartner,<br/> um die
          Verbindung zwischen der Debitorcloud und deinem DATEV System herzustellen.</p>
        <div class="row w-50 mt-3">
          <div class="col-12 ">
            <div class="form-floating">
              <input
                  autocomplete="off"
                  placeholder="DATEV Beraternummer"
                  id="consultantNumber"
                  :disabled="loading"
                  v-model.trim="debitorcloudFormData.consultantNumber"
                  class="form-control"
              />
              <label
                  for="consultantNumber">
                DATEV Beraternummer <span class="text-danger">*</span>
              </label>
            </div>
          </div>
        </div>

        <div class="row pt-3 w-50">
          <div class="col-12">
            <div class="form-floating">
              <input
                  autocomplete="off"
                  placeholder="Surname Firstname"
                  id="emailContactPerson"
                  type="email"
                  :disabled="loading"
                  v-model.trim="debitorcloudFormData.contactPersonName"
                  class="form-control"/>
              <label
                  for="emailContactPerson"
              >Name IT-Ansprechpartner <span class="text-danger">*</span></label>
            </div>
          </div>
        </div>

        <div class="row pt-3 w-50">
          <div class="col-12">
            <div class="form-floating">
              <input
                  autocomplete="off"
                  placeholder="a@example.com"
                  id="emailContactPerson"
                  type="email"
                  :disabled="loading"
                  v-model.trim="debitorcloudFormData.contactPersonEmail"
                  class="form-control"/>
              <label
                  for="emailContactPerson"
              >E-Mail IT-Ansprechpartner <span class="text-danger">*</span></label>
            </div>
          </div>
        </div>

        <p class="mt-4 text-start">
          Zwischen E-Mail IT Ansprechpartner & Workspace Name:<br/>
          Bitte lege hier den Namen für deine Debitorcloud Instanz fest (z.B. musterkanzlei).<br/>
          Dies wird dann später die URL, über die du deine persönliche Debitorcloud erreichen kannst.
        </p>

        <div class="row w-50 mt-3">
          <div class="col-12">
            <div class="input-group">
              <div class="form-floating flex-grow-1">
                <input
                    autocomplete="off"
                    placeholder="meineKanzlei"
                    id="workspaceName"
                    type="email"
                    :disabled="loading"
                    v-model.trim="debitorcloudFormData.workspaceName"
                    class="form-control"/>
                <label
                    for="emailContactPerson"
                >Workspace Name <span class="text-danger">*</span></label>
              </div>
              <div class="input-group-text">
                .debitorcloud.com
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 d-flex align-items-center justify-content-start">
          <div>
            <button class="btn btn-primary px-3 py-2"
                    :disabled="!formValid() || submitLoading"
                    @click="submitDebitorcloudForm()">
              Debitorcloud erstellen
            </button>
            <div v-if="submitLoading" class="ms-2">
              <div class="spinner-border text-primary text-center" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="successText !== ''">
        <div v-html="successText" class="successText mt-5 text-size"></div>
      </template>

      <template v-if="errorText !== ''">
        <div v-html="errorText" class="errorText mt-5 text-size text-center"></div>
      </template>
    </div>
  </div>
</template>
<script>
import {useRoute} from "vue-router";
import RequestManager from "@/services/RequestManager";
import {useToast} from "vue-toastification";

export default {
  name: "DebitorcloudModule",
  data: () => ({
    errorText: '',
    successText: '',
    loading: true,
    loadingText: null,
    debitorcloudFormData: {
      consultantNumber: null,
      contactPersonEmail: null,
      contactPersonName: null,
      workspaceName: null,
      type: null
    },
    submitLoading: false,
    customerContractId: null,
    documentsSigned: null,
    instanceCreated: null,
    imgSource: null
  }),
  created() {
    const route = useRoute();
    const routeParams = route.params;

    if (route.query !== undefined && route.query.type !== undefined) {
      this.debitorcloudFormData.type = route.query.type;
    }

    if (routeParams['customerContractId'] !== undefined && routeParams['customerContractId'] !== null) {
      this.customerContractId = routeParams['customerContractId'];
      this.loading = false;

      RequestManager.get('/customerContract/' + this.customerContractId + '/logo').then(
          async logoResponse => {
            const logoResult = await logoResponse.json();
            if (logoResult['logo'] !== undefined) {
              this.imgSource = logoResult['logo'];
            }
          }
      ).catch((error) => {
        console.log(error);
      })


      RequestManager.get('/customerContract/' + this.customerContractId + '/status').then(
          async responseSigned => {
            const resultSigned = await responseSigned.json();
            this.loading = false;
            this.loadingText = null;
            this.documentsSigned = !(resultSigned['signed'] !== undefined && resultSigned['signed'] === false);
            this.instanceCreated = (resultSigned['instanceCreated'] !== undefined && resultSigned['instanceCreated'] === true);
          }
      ).catch((error) => {
        console.log(error);
        this.loading = false;
        this.loadingText = null;
        const toast = useToast();
        toast.error("Bei der Überprüfung, ob die Dokumente schon unterzeichnet wurden ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
      })


    } else {
      this.loading = false;
      this.loadingText = null;
      const toast = useToast();
      toast.error("Beim Aufrufen der Seite zur Erstellung der Debitorcloud ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
      this.text = "Beim Aufrufen der Seite zur Erstellung der Debitorcloud ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!";
    }

  },
  methods: {
    submitDebitorcloudForm() {
      this.submitLoading = true;
      RequestManager.post('/debitorcloud/create/customerContractId/' + this.customerContractId, this.debitorcloudFormData).then(
          async response => {
            if (response.status === 409) {
              const toast = useToast();
              toast.error("Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten! Melden Sie das Problem bei support@riecken.io!");
              this.errorText = "Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten, weil der Workspace-Name schon existiert und dieser eindeutig sein muss!";
            } else {
              response = await response.json();
              if (response['status'] !== undefined && response['status'] !== null && response['status'] === 'OK') {
                this.successText = "Deine Debitorcloud-Instanz wurde erfolgreich erstellt. <br/> Die Registrierung ist nun abgeschlossen. Über weitere Vorgänge informieren wir dich per E-Mail!";
                this.errorText = '';
              } else {
                const toast = useToast();
                toast.error("Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
                this.errorText = "Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten! <br/> Melde das Problem bei support@riecken.io!";
              }
            }

            this.submitLoading = false;
            this.loading = false;
            this.loadingText = null;
          }).catch(async error => {
        this.loading = false;
        this.loadingText = null;
        this.submitLoading = false;
        const toast = useToast();
        toast.error("Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten! Melde das Problem bei support@riecken.io!");
        this.errorText = "Beim Erstellen der Debitorcloud-Instanz ist ein Fehler aufgetreten! <br/> Melde das Problem bei support@riecken.io!";

        console.log(error);
      });
    },
    formValid() {

      if (this.debitorcloudFormData.consultantNumber === null || this.debitorcloudFormData.consultantNumber === '') {
        return false;
      }
      if (this.debitorcloudFormData.contactPersonEmail === null || this.debitorcloudFormData.contactPersonEmail === '') {
        return false;
      }
      if (this.debitorcloudFormData.contactPersonName === null || this.debitorcloudFormData.contactPersonName === '') {
        return false;
      }
      if (this.debitorcloudFormData.workspaceName === null || this.debitorcloudFormData.workspaceName === '') {
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>
.block_center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15rem;
}

.text-size {
  font-size: 1.75rem;
}
</style>
