<template>
  <div class="mb-5 pb-5 mt-5">
    <div class="text-center mt-5">
      <img class="img-size mt-3" :src="imageSource" alt="Registrierungs-Formular-Logo"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: {
    imageSource: String,
    registrationForm: String
  },
  watch: {}

}
</script>

<style scoped>

nav {
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
}

@media (min-width: 769px) {
  .img-size {
    width: 35%;
  }

}

@media (max-width: 769px) {
  .img-size {
    width: 100%;
  }
}


</style>
