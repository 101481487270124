import {createApp} from 'vue'
import 'maz-ui/css/main.css'
import {createRouter, createWebHistory} from "vue-router";
import VueClickAway from "vue3-click-away";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue'
import RegistrationForm from "@/components/RegistrationForm";
import {vMaska} from "maska";
import StripeSuccess from "@/components/StripeSuccess";
import MazBtn from 'maz-ui/components/MazBtn'
import MazInput from 'maz-ui/components/MazInput'
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput'
import BasicModule from "@/components/modules/BasicModule";
import FileConverterModule from "@/components/modules/FileConverterModule";
import DebitorcloudModule from "@/components/modules/DebitorcloudModule";
import CloudgatewayModule from "@/components/modules/CloudgatewayModule";

const routes = [

    {
        path: "/success",
        name: "StripeSuccess",
        component: StripeSuccess,
    },
    {
        path: "/module/basic/:customerContractId",
        name: "BasicModule",
        component: BasicModule,
    },
    {
        path: "/module/fileConverter/:customerContractId",
        name: "FileConverterModule",
        component: FileConverterModule,
    },
    {
        path: "/module/debitorcloud/:customerContractId",
        name: "DebitorcloudModule",
        component: DebitorcloudModule,
    },
    {
        path: "/module/cloudgateway/:customerContractId",
        name: "CloudgatewayModule",
        component: CloudgatewayModule,
    },
    {
        path: "/:pathMatch(.*)*",
        name: "RegistrationForm",
        component: RegistrationForm,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

const app = createApp(App);
app.use(router);
app.use(VueClickAway);
app.use(Toast);
app.directive("maska", vMaska);
app.component('MazBtn', MazBtn)
app.component('MazInput', MazInput)
app.component('MazPhoneNumberInput', MazPhoneNumberInput)
app.mount('#app')

