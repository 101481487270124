<template>
  <div class="row">
    <div class="col-12">
      <p>Tipp: Bei Steuerberatern, die nicht im Handelsregister / Firmenbuch stehen, nur nach dem Nachnamen (nicht in
        Kombination mit dem Vornamen) suchen </p>
      <div class="input-group">
        <div class="form-floating form-floating-group flex-grow-1">
          <input type="text"
                 autocomplete="off"
                 :disabled="foundCompany"
                 v-model="companySearchTextModel"
                 placeholder="Suche nach Unternehmen..."
                 class="form-control "
                 id="companySearch"
                 @keydown="keyDownCompanySearch"
                 @keyup="keyUpCompanySearch"
                 @click="loadCompanies(companySearchTextModel)"
          />
          <label for="companySearch">Unternehmen...</label>
        </div>
        <span class="input-group-text input-field-icon"
              @click="loadCompanies(companySearchTextModel)"
              v-if="!foundCompany">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
                 viewBox="0 0 16 16">
              <path
                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </span>
        <span class="input-group-text input-field-icon"
              @click="cancelCompany"
              v-if="foundCompany">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x"
                 viewBox="0 0 16 16">
              <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
            </svg>
          </span>
      </div>
      <div v-if="isTyping"
           class="live-result
            w-100
            py-2
  text-center"
           :class="[registrationForm === 'basic' ? 'live-result-width-basic' :  'live-result-width-not-basic']">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>

      <div v-if="companiesSearchResult && !isTyping"
           class="w-100
                  live-result"
           :class="[registrationForm === 'basic' ? 'live-result-width-basic' :  'live-result-width-not-basic']"
           v-click-outside-of-element="onClose"

      >
        <ul class="list-group">
          <li class=" text-left
                      py-2
                      list-group-item"
              v-for="company in companiesSearchResult"
              :key="company"
              :value="company"
              @mouseover="hoveredElement = company"
              @mouseout="hoveredElement = null"
              :class="{hoveringListElement: hoveredElement === company}"

              @click="selectCompany(company)">
            {{ company.name + '  -  Quelle: ' + company.source }}
          </li>
        </ul>
      </div>
      <div v-if="!foundCompany  && !companyNotFound" class="font-italic mt-2">
        Suche zuerst nach deinem Unternehmen und wähle es aus,
        um mit der Registrierung fortzufahren.
      </div>
      <div v-if="companyNotFound" class="font-italic mt-2">
        Gib deine Unternehmens-Daten manuell ein
      </div>
      <div v-if="!foundCompany">
        <template v-if="customDataInputPossible==='1'">
          <a class="text-primary link-company-not-found"
             @click="companyNotFoundAction">Ich habe mein Unternehmen nicht gefunden</a>
        </template>
        <template v-if="customDataInputPossible === '0' && supportEmail !== null">
          <a class="text-primary link-company-not-found"
             :href="mailToLink">Ich habe mein Unternehmen nicht gefunden</a>
        </template>
      </div>
    </div>
  </div>

</template>

<script>
import {directive} from "vue3-click-away";
import RequestManager from "@/services/RequestManager";
import {useToast} from "vue-toastification";

export default {
  name: "CompanySearch",
  props: {
    companySearchText: String,
    company: String,
    registrationForm: String,
    customDataInputPossible: String,
    supportEmail: String
  },
  emits: ['company', 'companyNotFoundOrSet', 'cancelCompany'],
  directives: {
    ClickOutsideOfElement: directive,
  },
  data: () => ({
    companySearchTextModel: null,
    foundCompany: null,
    debounce: null,
    companiesSearchResult: null,
    debounceTimeout: 400,
    hoveredElement: null,
    isTyping: false,
    companyNotFound: false,
    searchActive: true,
    subjectNotFoundCompany: 'Registrierungsformular - Unternehmen nicht gefunden'
  }),
  created() {
    this.companySearchTextModel = this.companySearchText;
  },
  watch: {
    companySearchTextModel: function (newSearchText) {
      this.loadCompanies(newSearchText);
    }
  },
  computed: {
    mailToLink() {
      const encodedSubject = encodeURIComponent(this.subjectNotFoundCompany);
      return `mailto:${this.supportEmail}?subject=${encodedSubject}`;
    }
  },
  methods: {
    cancelCompany() {
      this.searchActive = false;
      this.companySearchTextModel = null;
      this.foundCompany = null;
      this.companiesSearchResult = null;
      this.hoveredElement = null;
      this.isTyping = false;
      this.companyNotFound = false;
      this.debounce = null;

      this.$emit('cancelCompany', true);
    },
    loadCompanies(newSearchText) {
      clearTimeout(this.debounce)
      if (this.searchActive) {
        this.isTyping = true;
        if (newSearchText === null || newSearchText === '') {
          this.companySearchTextModel = '';
          this.companiesSearchResult = null;
          this.isTyping = false;
          return;
        }
        if (newSearchText.length > 1) {
          this.debounce = setTimeout(() => {
            this.companySearchTextModel = newSearchText;
            RequestManager.get('/company/search/' + this.companySearchTextModel, 'https://theo-api.riecken.io/index.php')
                .then(async companies => {
                  this.companiesSearchResult = await companies.json();
                  this.isTyping = false;
                })
                .catch(error => {
                  const toast = useToast();
                  toast.error("Es gab einen Fehler bei der Suche nach Ihrem Unternehmen. Melde das Problem bei support@riecken.io!");
                  this.isTyping = false;
                  console.log(error);
                })
          }, this.debounceTimeout)
        }

      } else {
        this.companiesSearchResult = null;
        this.searchActive = true;
      }
    },
    selectCompany(company) {
      this.hoveredElement = null;
      if (company != null) {
        this.searchActive = false;
        this.foundCompany = company;
        this.companySearchTextModel = company.name ?? '';
        this.$emit('company', company);
        this.$emit('companyNotFoundOrSet', true);
      }
      this.companiesSearchResult = null;
    },
    onClose() {
      this.selectCompany(null);
    },
    companyNotFoundAction() {
      this.companyNotFound = true;
      this.$emit('companyNotFoundOrSet', true);
    },
    keyUpCompanySearch(event) {
      if (event.keyCode === 13) {
        if (this.hoveredElement) {
          this.selectCompany(this.hoveredElement);
        }
      }
    },
    keyDownCompanySearch(event) {
      if (event.keyCode === 38) {
        if (this.companiesSearchResult) {
          if (!this.hoveredElement) {
            this.hoveredElement = this.companiesSearchResult[0] ?? null;
          } else {
            let index = this.companiesSearchResult.indexOf(this.hoveredElement);
            if (index !== -1) {
              if (index === 0) {
                this.hoveredElement = null;
              } else {
                this.hoveredElement = this.companiesSearchResult[--index];
              }
            } else {
              this.hoveredElement = null;
            }
          }

        }

      } else if (event.keyCode === 40) {
        if (this.companiesSearchResult) {
          if (!this.hoveredElement) {
            this.hoveredElement = this.companiesSearchResult[0] ?? null;
          } else {
            let index = this.companiesSearchResult.indexOf(this.hoveredElement);

            if (index !== -1) {
              if (index === (this.companiesSearchResult.length - 1)) {
                this.hoveredElement = this.companiesSearchResult[0];
              } else {
                this.hoveredElement = this.companiesSearchResult[++index];
              }
            } else {
              this.hoveredElement = null;
            }
          }
        }
      } else {
        if (event.keyCode !== 13) {
          this.hoveredElement = null;
        }
      }
    }
  }

}

</script>

<style scoped>

.live-result {
  position: absolute;
  z-index: 3;
  background-color: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  max-height: 20rem;
  overflow-y: scroll;
}

@media (min-width: 1400px) {
  .live-result-width-basic {
    max-width: 81rem;
  }

  .live-result-width-not-basic {
    max-width: 47rem;
  }

}

@media (max-width: 1399px) {
  .live-result-width-basic {
    max-width: 70rem;
  }

  .live-result-width-not-basic {
    max-width: 41rem;
  }
}


@media (max-width: 1199px) {

  .live-result-width-basic {
    max-width: 59rem;
  }

  .live-result-width-not-basic {
    max-width: 34rem;
  }
}

@media (max-width: 991px) {
  .live-result-width-basic {
    max-width: 44rem;
  }

  .live-result-width-not-basic {
    max-width: 26rem;
  }
}

@media (max-width: 767px) {
  .live-result-width-basic {
    max-width: 33rem;
  }

  .live-result-width-not-basic {
    max-width: 19rem;
  }
}

@media (max-width: 575px) {
  .live-result-width-basic {
    max-width: 35rem;
  }

  .live-result-width-not-basic {
    max-width: 81rem;
  }
}

.hoveringListElement {
  cursor: pointer;
  color: white;
  background-color: #007bff;
}

.link-company-not-found {
  font-size: 13px;
  cursor: pointer;
}

.form-floating-group input {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-field-icon {
  cursor: pointer;
}

</style>
