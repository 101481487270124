<template>
  <h3 class="mb-3">Deine Bestellung</h3>
  <div class="border rounded">
    <ul class="list-group">
      <li class="list-group-item" v-bind:key="article.id" v-for="article in articles">
        <div class="row">
          <div class="col-7">
            <h6 v-if="article.price === 'USAGE'">{{ article.name }}</h6>
            <div v-if="!article.price.toString().includes('USAGE')" class="title-flex">
              <h6 class="bold">{{ article.name }}</h6>
              <small class="text-body-secondary" v-html="article.description"></small>
            </div>
          </div>
          <div class="col-5">
            <div class="text-muted text-right" v-if="!article.price.toString().includes('USAGE')">
              {{ currencyFormat.format(article.price) }}
            </div>
            <p class="text-muted text-right" v-if="article.price === 'USAGE'" v-html="article.description"></p>
          </div>
        </div>
      </li>
    </ul>
  </div>

</template>

<script>

export default {
  name: "CheckoutComponent",
  props: {
    registrationForm: String,
    articles: Array
  },
  data: () => ({
    currencyFormat: new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'})
  }),
  methods: {
    getPrice() {
      let sum = 0;
      const specialPrices = [];

      /*
       * if there are articles,
       * iterate through them and sum up price
       *
       * if price is of type 'usage',
       * add them to second array in order to add special string price description later
       */
      if (this.articles !== undefined
          && Array.isArray(this.articles)
          && this.articles.length > 0) {
        this.articles.forEach((article) => {
          if (article.price.toString() === 'USAGE') {
            specialPrices.push(article.description);
          }
          if (!isNaN(article.price)) {
            sum += +article.price;
          }
        })
      }

      sum = this.currencyFormat.format(sum);

      specialPrices.forEach((specialPrice) => {
        sum += '<br/> + ' + specialPrice;
      });

      return sum;
    }
  }
}
</script>

<style scoped>

.bold {
  font-weight: bold !important
}

.title-flex {
  display: flex;
  flex-direction: column;
}

</style>
